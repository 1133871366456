import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Hydrate } from 'react-query';
import QueryProvider, {
  ModalErrorWrapper,
  ModalLeadWrapper,
  TrackingContextProvider,
} from '@vfit/shared/providers';
import { PageContextProvider } from '@vfit/consumer/providers';
import { ErrorBoundary, ScrollRestoration, YextSearch } from '@vfit/consumer/components';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppDynamics, HeadSeo } from '@vfit/shared/components';
// eslint-disable-next-line import/no-relative-packages
import '../../../libs/shared/themes/src/lib/sunrise/styles/globals.css';
import '../styles/carousel/offerCarousel.scss';
import 'swiper/css';
import 'swiper/css/pagination';


type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const DefaultApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [loading, setLoading] = useState<boolean>(true);
  const getLayout = Component.getLayout ?? ((page) => page);
  const { elements } = pageProps?.page?.params || {};
  const { seo } = elements || {};


  useEffect(() => {
    setLoading(false);
  }, []);

  const loadingComponent = () => <div />;

  return (
    <>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://assets.sitescdn.net/answers-search-bar/v1.2/answers.css"
        />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <AppDynamics isConfig />
      <ErrorBoundary>
        <QueryProvider>
          <Hydrate state={pageProps.dehydratedState}>
            <TrackingContextProvider>
              <PageContextProvider>
                <ScrollRestoration />
                <YextSearch />
                <AppDynamics />
                <ModalLeadWrapper />
                <ModalErrorWrapper />
                <ReactQueryDevtools initialIsOpen={false} />
                <HeadSeo {...seo} />
                {loading && loadingComponent()}
                {!loading && getLayout(<Component {...pageProps} />)}
              </PageContextProvider>
            </TrackingContextProvider>
          </Hydrate>
        </QueryProvider>
      </ErrorBoundary>
    </>
  );
};

export default DefaultApp;

